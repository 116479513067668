import React, { useEffect } from "react";
import HomeBanner from "./UI/HomeBanner";
import Divider from "./UI/Divider";
import about from "../images/about.jpg";
import EmbedMap from "./UI/EmbedMap";
import { IPageProps } from "../App";

const HomePage: React.FC<IPageProps> = ({onLoad}) => {

    useEffect(() =>
    {
        onLoad();
    }, [onLoad]);

    return (
        <>
            <div className="content home-page home-banner">
                <HomeBanner />
            </div>
            <Divider />
            <div className="content home-page">
                <div className="about">
                    <div>
                        <h3>o nama</h3>
                        <p>U današnjici, gdje se gotovo sva komunikacija odvija preko ekrana, htjeli smo napraviti mjesto gdje se možete opustiti i uživati u kvalitetnom vremenu s dragim ljudima. Osnovani sa misijom da inspriramo svijet da igra više društvenih igara, vjerujemo da je “Kafić” mjesto stvoreno za male trenutke druženja, razgovora, fine kave i zabave.</p>

                        <p>Na našim policama možete istražiti više od 100 igara. Od klasičnih igara poput Monopoly-a do šaha, kartaških igara, obiteljskih društvenih igara, do složenijih, strateških - i zahtjevnijih igara. Ovdje su svi dobrodošli! Bilo da ste mladi ili stari, početnik ili iskusan igrač društvenih igara - jedino što je važno jest da ste spremni za zabavu!</p>                        
                    </div>
                    <img src={about} alt="image taken at Kafic" />
                </div>
            </div>

            <Divider green reverse />
            <div className="content home-page home-banner home-quote">
                <q>if you're not prepared to lose every friend you have over a board game, you're not playing hard enough.</q>
            </div>
            <Divider green />
            <EmbedMap />
        </>
    );
};

export default HomePage;