import React, { ChangeEvent, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Game } from "../typings";
import api from "../services/BackendApi";
import GameComponent from "./games/Game";
import {
    useNavigate,
    useLocation,
  } from "react-router-dom";
import "./GamesPage.css";
import { createFilterValues, emptyFilterValues, filterAndSortGames, Filters, FilterValues, SortType } from "./games/GamesHelper";
import SearchInput from "./UI/SearchInput";
import Dropdown, { SortDropdown } from "./UI/Dropdown";
import GameModal from "./games/GameModal";
import DropdownMultiple from "./UI/DropdownMultiple";
import { IPageProps } from "../App";

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

const GamesPage: React.FC<IPageProps> = ({onLoad}) => {

    const [games, setGames] = useState<Game[]>();
    const [visibleGames, setVisibleGames] = useState<string[]>([]);
    const [filters, setFilters] = useState<Filters>({});
    const [sortParam, setSortParam] = useState<SortType>(SortType.AZ);
    const [filterValues, setFilterValues] = useState<FilterValues>(emptyFilterValues);
    const [search, setSearch] = useState<string>("");
    const [openedGame, setOpenedGame] = useState<Game>();
    const query = useQuery();
    const navigate = useNavigate();

    useEffect(() =>
    {
        onLoad();
    }, [onLoad]);

    useEffect(() =>
    {
        document.body.style.overflow = openedGame? "hidden" : "auto";

        return () =>
        {
            document.body.style.overflow = "auto"
        };
    }, [openedGame]);

    //First load of games
    useEffect(() => {
        if(games === undefined)
        {
            setGames([]);
            refreshGames();
        }
    }, [games]);

    //Read search query and update filters
    useLayoutEffect(() =>
    {
        if(query)
        {
            const newFilters: Filters = {
                age: query.get("age") || undefined,
                duration: query.get("duration") || undefined,
                language: query.get("language") || undefined,
                players: query.get("players") || undefined,
                tags: query.get("tags")?.split(","),
                search: query.get("search") || undefined
            };

            if(newFilters.search && newFilters.search.length > search.length && search.length === 0)
            {
                setSearch(newFilters.search);
            }

            setFilters(newFilters);
        }

    }, [query, search]);

    //Apply filters
    useEffect(() =>
    {
        if(!games)
        {
            return;
        }
        const visibleGames = filterAndSortGames(games, filters, sortParam);
        setVisibleGames(visibleGames);
    }, [games, filters, sortParam]);

    const refreshGames = useCallback(() =>
    {
        api.getGames().then(res =>
        {
            setFilterValues(createFilterValues(res.entries))
            setGames(res.entries);
            setVisibleGames(filterAndSortGames(res.entries, filters, sortParam));
        });
    }, [filters, sortParam]);

    const updateQuery = useCallback((k: keyof Filters, value: string) =>
    {
        if(value.length === 0 || (k === "search" && value.length < 2))
        {
            query.delete(k);
        }
        else
        {
            query.set(k, value);
        }

        setFilters({});

        navigate({
            pathname: "/games",
            search: query.toString()
        });
    }, [query]);

    const updateQueryForTags = useCallback((values: string[]) =>
    {
        if(values.length === 0)
        {
            query.delete("tags");
        }
        else
        {
            query.set("tags", values.join(","));
        }

        setFilters({});

        navigate({
            pathname: "/games",
            search: query.toString()
        });

    },[query]);

    const createSelectOnChange = (k: keyof Filters) => (value: string) => updateQuery(k, value);

    const onSortChange = useCallback((value: string) =>
    {
        const newSortParam = Number(value);

        if(!isNaN(newSortParam) && sortParam !== newSortParam)
        {
            setSortParam(newSortParam);
        }
    }, [sortParam]);

    const onSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) =>
    {
        const newSearch = e.currentTarget.value;
        if(search === newSearch)
        {
            return;
        }

        setSearch(newSearch);
        updateQuery("search", newSearch);
    }, [search]);


    return (
        <div className="content games-page">
            <div className="toolbar">
                <SearchInput name="search" onChange={onSearchChange} value={search} placeholder="npr. Monopoly"/>
                <DropdownMultiple name="Kategorija" options={filterValues.tags} values={filters.tags} onChange={updateQueryForTags} />
                <Dropdown onChange={createSelectOnChange("players") } name="Broj igrača" values={filterValues.players.map(value => ({value}))} value={filters.players}/>
                {/* <SortDropdown onChange={onSortChange} value={sortParam} /> */}
                {/* <Dropdown onChange={createSelectOnChange("duration") } name="Trajanje igre" values={filterValues.duration} value={filters.duration}/> */}
                {/*<Dropdown onChange={createSelectOnChange("age") } name="Uzrast" values={filterValues.age} value={filters.age}/>*/}
                {/* <SingleSelectFilter onChange={createSelectOnChange("language") } name="language" values={filterValues.language} value={filters.language}/> */}

            </div>
            <div className="results">{visibleGames.length} rezultati</div>
            <div className="cards">
                {games?.filter(g => visibleGames.some(v => v === g._id)).map((game, i) => <GameComponent key={i} {...game} openGame={() => setOpenedGame(game)} />) || <div>nema nista :-(</div>}
            </div>
            <GameModal game={openedGame} close={() => setOpenedGame(undefined)} />
        </div>
    );
};





export default GamesPage;