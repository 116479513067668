import React, { ChangeEvent, Fragment, ReactText, useEffect, useRef, useState } from "react";
import { getSortTypeLabel, SortType } from "../games/GamesHelper";
import { ReactComponent as ArrowUp} from "./arrow-up.svg";
import { ReactComponent as ArrowDown} from "./arrow-down.svg";

interface DropdownValue
{
    value: string;
    label?: string;
}

interface DropdownProps
{
    onChange: (value: string) => void;
    name: string;
    values?: DropdownValue[];
    value?: ReactText;
}
const Dropdown: React.FC<DropdownProps> = ({name, values = [], onChange, value}) =>
{
    const [open, setOpen] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);


    React.useEffect(() => {
        const handleClick = (event: any) => {
          if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false);
          }
        };
    
        document.addEventListener('click', handleClick);
    
        return () => {
          document.removeEventListener('click', handleClick);
        };
    }, [ref]);

    const onOptionClick = (v: string) => 
    {
        let nv = v === "---"? "" : v;
        setOpen(false);
        onChange(nv);
    };
    const selectedValue = value? values?.find(v => v.value == value) : undefined;

    return (
        <div className="dropdown-wrapper" ref={ref}>
        <div className={`dropdown${open? " dropdown--open" : ""}${!!value? " dropdown--value" : ""}`} onClick={() => setOpen(!open)}>
            <ArrowDown className="arrow"/>
            <span>{name}{selectedValue? ": " + (selectedValue.label || selectedValue.value) : ""}</span>
        </div>
        <div className={`dropdown-options${open? " dropdown-options--open" : ""}`}>
            {[{value: "---"}, ...values].map((v, i) => !!(v)? 
                <div className={`option${v.value===value? " option--selected" : ""}`} key={i} onClick={() => onOptionClick(v.value)}>{v.label || v.value}</div>
            : null)}
            </div>
        </div>
    );
};

interface SortDropdownProps
{    
    onChange: (v: string) => void;
    value: SortType;
}
export const SortDropdown: React.FC<SortDropdownProps> = (props) =>
{
    const values: DropdownValue[] = Object.keys(SortType).map((value, i) =>
    {
        const label = getSortTypeLabel(i);
        return {value, label};
    });

    return <Dropdown name="Sortiraj po" value={props.value} values={values} onChange={props.onChange} />;
};

export default Dropdown;