import { CollectionResponse, Game, MenuResponse } from "../typings";

const BACKEND_URL = "https://cms.pubkafic.com";
const API_TOKEN = "65010544d30207c6183b19a3bb8a9d";

class BackendApi
{
    private _url: string;
    private _token: string;

    public constructor(url: string = BACKEND_URL, token: string = API_TOKEN) {
        this._url = url;
        this._token = token;
    }

    public get baseUrl() { return this._url; }


    private fetchData<T>(path: string): Promise<T> {
        return fetch(this._url + path, {
            headers: { 'Cockpit-Token': this._token }
        })
        .then(res => res.json());
    }

    public getGames() {
        return this.fetchData<CollectionResponse<Game>>("/api/collections/get/games");
    }

    public getMenu() {
        return this.fetchData<MenuResponse>("/api/singletons/get/Menu")
    }

}

export const prefixImagePath = (path?: string, query?: string) => path && path.replace(" ", "").length > 0? `https://cms.pubkafic.com/${path}${query? "?"+query : ""}` : undefined;

const api = new BackendApi();

export default api;