import React from "react";
import dividerImage from "./divider.svg";
import dividerImageGreen from "./divider-green.svg";
interface Props
{
    reverse?: boolean;
    green?: boolean;
}

const Divider: React.FC<Props> = (props) =>
{
    const img = `url(${props.green? dividerImageGreen : dividerImage})`;
    return <div className={`divider${props.reverse? " divider--reverse" : ""}${props.green? " divider--green" : ""}`} style={{backgroundImage: img}} />
};

export default Divider;