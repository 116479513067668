import React from "react";
import divider from "./UI/divider.svg";
import facebook from "../icons/facebook.svg";
import instagram from "../icons/instagram.svg";
import telegram from "../icons/telegram.svg";
import whatsapp from "../icons/whatsapp.svg";
import Divider from "./UI/Divider";


const footerLinks = [
    { icon: facebook, url: "https://www.facebook.com/pubkafic", alt: "visit our facebook page" },
    { icon: instagram, url: "https://www.instagram.com/pubkafic", alt: "visit our instagram page" },
    { icon: telegram, url: undefined, alt: "contact us with telegram" },
    { icon: whatsapp, url: "https://wa.me/+38763050800", alt: "contact us with whatsapp" },
];

const Footer: React.FC = () =>
{
    return (
        <footer>
            <Divider />
            <div className="footer-icons">
                {footerLinks.map((link, i) => 
                !link.url? null : (
                    <a key={i} href={link.url} className="footer-icon" rel="noopener noreferrer" target="_blank">
                        <img src={link.icon} alt={link.alt}/>
                    </a>
                ))}               
            </div>
            <div className="footer-content">© 2022 kafić all rights reserved</div>
            <Divider reverse />
        </footer>
    );
};

export default Footer;