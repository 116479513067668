import React, { Fragment } from "react";
import { Game } from "../../typings";
import {ReactComponent as CloseIcon} from "./close-icon.svg";
import { prefixBackGroundImagePath, Tags } from "./Game";
import {ReactComponent as PlayersIcon} from "../../icons/players-icon.svg";
import {ReactComponent as DurationIcon} from "../../icons/duration-icon.svg";
import { prefixImagePath } from "../../services/BackendApi";


interface Props
{
    game?: Game;
    close: () => void;
}

const GameModal: React.FC<Props> = ({game, close}) =>
{
    if(!game)
    {
        return null;
    }

    const description = game.description?.replace('<a', '<a target="_blank" rel="noopener noreferrer"') || "<p></p>";

    return (
        <Fragment>
        <div className="modal-overlay" onClick={close}></div>
        <div className="game-modal">
            <div className="game-modal-content glow-on-hover">
                <CloseIcon className="close-icon" onClick={close}/>
                <h2 className="title">{game.name}</h2>
                <div className="modal_image">
                    <img src={prefixImagePath(game.image.path, "rand=23102")} />
                </div>
                <Tags tags={game.tags} />
                <div className="game-info"><PlayersIcon />  {game.min_players}-{game.max_players}</div>
                {game.duration? <div className="game-info"><DurationIcon />  {game.duration?.replace("min", "")} min</div> : null}
                <div className="game-info">Uzrast: {game.age}+</div>
                <div className="description" dangerouslySetInnerHTML={{__html: description}}></div>
            </div>
        </div>

        </Fragment>

    )
};

export default GameModal;