import React, { ReactText } from "react";
import { Game } from "../../typings";
import pins from "./pins.png";
import {ReactComponent as PlayersIcon} from "../../icons/players-icon.svg";
import {ReactComponent as DurationIcon} from "../../icons/duration-icon.svg";
import {ReactComponent as TagIcon} from "../../icons/tag.svg";
import { prefixImagePath } from "../../services/BackendApi";
export interface GameComponentProps extends Game
{
    openGame: () => void;
}

export const prefixBackGroundImagePath = (path?: string) => path && path.replace(" ", "").length > 0? `url('https://cms.pubkafic.com/${path}'` : `url('${pins}'`;

const GameComponent: React.FC<GameComponentProps> = (props) =>
{

    let numberOfPlayers = props.min_players;

    if(props.max_players && props.max_players !== props.min_players)
    {
        numberOfPlayers = props.min_players + "-" + props.max_players;
    }
    const durationText = props.duration?.toLowerCase().replace("min", "");
    return (
        <div className="game-component card" onClick={props.openGame}>
            <div className="card_image">
                <img src={prefixImagePath(props.image.path, "h=200") || pins} />
                <div className="badges">
                    {numberOfPlayers? <Badge text={numberOfPlayers} icon={<PlayersIcon />}/> : null}
                    {props.duration? <Badge text={durationText} icon={<DurationIcon />}/> : null }
                    {props.age? <Badge text={`${props.age}+`} /> : null}
                    {props.position_color && props.position_number && false? <Badge text={props.position_number} modifier={props.position_color} /> : null }
                </div>
            </div>
            <div className="card_content">
                <h4 className="card_title">{props.name}</h4>
                <Tags tags={props.tags} />
            </div>
            <div className="card_bottom">Pročitajte više</div>
        </div>
    );
};


export const Badge: React.FC<{text: ReactText, modifier?: string, icon?: JSX.Element}> = ({text, modifier, icon}) =>
    <div className={`badge${modifier? " badge--" + modifier : ""}`}>{icon}<span>{text}</span></div>;


export const Tags: React.FC<{tags?: string[]}> = ({tags}) =>
{
    if(!tags)
    {
        return null;
    }

    return (
        <div className="tags">
            {tags.map((tag, i) => <span className="tag" key={i}><TagIcon />{tag}</span>)}
        </div>
    );
};
export default GameComponent;