import React, { ChangeEvent, Fragment, ReactText, useEffect, useRef, useState } from "react";
import { getSortTypeLabel, SortType } from "../games/GamesHelper";
import { ReactComponent as ArrowUp} from "./arrow-up.svg";
import { ReactComponent as ArrowDown} from "./arrow-down.svg";

interface DropdownValue
{
    value: string;
    label?: string;
}

interface DropdownProps
{
    onChange: (values: string[]) => void;
    name: string;
    options?: string[];
    values?: string[];
}
const DropdownMultiple: React.FC<DropdownProps> = ({name, values = [], options = [], onChange}) =>
{
    const [open, setOpen] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);


    React.useEffect(() => {
        const handleClick = (event: any) => {
          if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false);
          }
        };
    
        document.addEventListener('click', handleClick);
    
        return () => {
          document.removeEventListener('click', handleClick);
        };
    }, [ref]);


    const onOptionClick = (v: string, isSelected: boolean) =>
    {
        if(isSelected)
        {
            return onChange(values.filter(cv => cv !== v));
        }

        return onChange([v, ...values]);
    };

    const applied = values?.length > 0? <small className="round">{values.length}</small> : null;

    return (
        <div className="dropdown-wrapper" ref={ref}>
        <div className={`dropdown dropdown--multiple${open? " dropdown--open" : ""}${!!(values.length)? " dropdown--value" : ""}`} onClick={() => setOpen(!open)}>
            <ArrowDown className="arrow"/>
            <span>{name} {applied}</span>
        </div>
        <div className={`dropdown-options dropdown-options--multiple${open? " dropdown-options--open" : ""}`}>
            {[...options].map((v, i) =>
            {  
                const isSelected = values.indexOf(v) !== -1;
                return (
                    <div className={`option`} key={i} onClick={() => onOptionClick(v, isSelected)}>
                        <input type="checkbox" checked={isSelected} onChange={() => null} /><span>{v}</span>
                    </div>
                );
            })}
            </div>
        </div>
    );
};

export default DropdownMultiple;