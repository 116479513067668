import React, { ChangeEvent } from "react";
import {ReactComponent as Icon} from "./icon-search.svg";


interface Props
{
    name: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
}

const SearchInput: React.FC<Props> = (props) =>
{
    return <div className="search-input">
        <Icon />
        <input type="text" {...props} />
    </div>;
};

export default SearchInput;