import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { UrlLink } from "../typings";
import logo from "../logoNew.svg";
import burgerMenuIcon from "../icons/burger-menu.svg";
import closeIcon from "../icons/close-icon.svg";

const links: UrlLink[] = [

    {url: "/", label: "o nama"},
    {url: "/games", label: "društvene igre"},
    {url: "/menu", label: "cjenik"}
];

const Header: React.FC<{}> = () =>
{
    const [mobileOpen, setMobileOpen] = useState<boolean>();
    const location = useLocation();

    const menuIcon = mobileOpen? closeIcon : burgerMenuIcon;

    return (
        <header className="app-header">

            <nav>
                <Link to={"/"}><img className="logo" src={logo} alt="kafic" /></Link>
                <div className="burger-menu">
                    <img src={menuIcon} alt="open menu" onClick={() => setMobileOpen(!mobileOpen)} />
                </div>
                <div className={`navigation${mobileOpen? " navigation--mobile-open" : ""}`}>
                    <div className="navigation-top-mobile">
                        <Link to={"/"} onClick={() => setMobileOpen(false)}><img className="logo" src={logo} alt="kafic" /></Link>
                        <div className="burger-menu">
                            <img src={menuIcon} alt="open menu" onClick={() => setMobileOpen(!mobileOpen)} />
                        </div>
                    </div>

                    {links.map((l,i) => <Link onClick={() => setMobileOpen(false)} key={i} className={location.pathname.toLowerCase() === l.url? "active" : ""} to={l.url}>{l.label}</Link>)}
                </div>
            </nav>
      </header>
    );
};

export default Header;
