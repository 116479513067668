import React from "react";
import "./GameShip.css";
import { ReactComponent as SpaceShip } from "./spaceship.svg";

interface Props
{
    onAnimationDone: () => void;
    animationDone: boolean;
}

interface State{
    startWiggle: boolean;
    renderedCharactersCounter: number;
    textIndex: number;
}



class GameShip extends React.PureComponent<Props, State>
{
    private _loadingTexts = ["Slijećemo", "Na", "Zemlju"];
    private _textAnimationTimer?: number;
    private _charTimeout = 120;


    state: State = {
        startWiggle: true,
        renderedCharactersCounter: 0,
        textIndex: 0
    };

    componentDidMount()
    {
        this.initTextRenderer();
    }

    initTextRenderer = () =>
    {
        const {textIndex, renderedCharactersCounter} = this.state;

        if(textIndex >= this._loadingTexts.length)
        {
            setTimeout(() => this.props.onAnimationDone(), 250);
            return;
        }

        if(this._loadingTexts[textIndex].length === renderedCharactersCounter)
        {
            window.clearTimeout(this._textAnimationTimer);
            this._textAnimationTimer = window.setTimeout(() =>
            {
                this.setState({textIndex: textIndex + 1, renderedCharactersCounter: 0});
                this.initTextRenderer();
            }, this._charTimeout);

            return;
        }

        this._textAnimationTimer = window.setTimeout(() =>
        {
            this.setState({renderedCharactersCounter: this.state.renderedCharactersCounter + 1});
            this.initTextRenderer();
        }, this._charTimeout);
    }



    render ()
    {
        const {startWiggle, renderedCharactersCounter, textIndex} = this.state;

        const animation = startWiggle? "shake 1.5s infinite" : undefined;

        const text = textIndex >= this._loadingTexts.length? "" : this._loadingTexts[textIndex];

        if(this.props.animationDone)
        {
            return null;
        }

        return (
            <div className="game-ship" style={{display: this.props.animationDone? "none": "flex"}} onClick={() => this.props.onAnimationDone()}>
                <SpaceShip style={{animation}} />
                <div style={{color: "white"}}>{text.substring(0, renderedCharactersCounter)}</div>
            </div>
        );
    }
};

export default GameShip;