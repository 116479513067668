import React from "react";
import { Link } from "react-router-dom";
import "./Banner.css";

const HomeBanner: React.FC = () =>
{
    return (
        <div className="banner">
            <div className="banner-content">
                <h1>kafić</h1>
                <p>Osnovani sa misijom da inspriramo svijet da igra više društvenih igara, vjerujemo da je “Kafić” mjesto stvoreno za male trenutke druženja.</p>
                <Link to="/menu" className="banner-button glow-on-hover">istraži meni</Link>
            </div>
        </div>
    );
};

export default HomeBanner;