import React from "react";

const EMBED_URL = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d724.9217777790384!2d17.591488835782883!3d43.38356596920155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134b3ff959de03e3%3A0x8207cfa2cdba5595!2sPub%20Kafi%C4%87!5e0!3m2!1sen!2sdk!4v1659255016388!5m2!1sen!2sdk";
const EmbedMap = () =>
{
    const style:React.CSSProperties = {
        border: 0,
        maxWidth: "100%",
        width: "100%",
        height: "500px"
    };
    
    return (
        <div className="map-component">
            <iframe src={EMBED_URL} width="100%" style={style} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
    );
};

export default EmbedMap;