import { isArray } from "util";
import { Game } from "../../typings";


export interface Filters
{
    age?: string;
    duration?: string;
    language?: string;
    players?: string;
    tags?: string[];
    search?: string;
}

export interface FilterValues
{
    age: string[];
    duration: string[];
    language: string[];
    players: string[];
    tags: string[];
}

export const emptyFilterValues: FilterValues =
{
    age: [],
    duration: [],
    language: [],
    players: [],
    tags: []
};

export const createFilterValues = (games: Game[]): FilterValues =>
{
    const filtervalues = games.reduce<FilterValues>((pv: FilterValues, cv: Game) =>
    {
        const { age, duration, language, players, tags} = pv;
        const gameTags = cv.tags || [];
        const gameLang = cv.language || [];

        return {
            age: [...age, cv.age?.toString()],
            duration: [...duration, cv.duration],
            language: [...language, ...gameLang],
            players: [...players, cv.min_players, cv.max_players],
            tags: [...tags, ...gameTags]
        }
    }, emptyFilterValues);

    return {
        age: uniqueValues(filtervalues.age).sort((a, b) => Number(a) - Number(b)),
        duration: uniqueValues(filtervalues.duration),
        language: uniqueValues(filtervalues.language),
        players: uniqueValues(filtervalues.players).sort((a, b) => Number(a) - Number(b)),
        tags: uniqueValues(filtervalues.tags)
    };
};

const uniqueValues = (values: string[]) => values.reduce((pv: string[], cv: string) => 
{
    const value = cv?.trim()?.replace("igre", "igra");
    if(!value || pv.indexOf(value) !== -1)
    {
        return pv;
    }
    return [
        ...pv,
        value
    ];
}, []);


const applyFilter = (game: Game, k:  keyof Filters, value: string | string[]) =>
{
    if(!value || value.length === 0)
    {
        return true;
    }
    let gamePropertyValue = undefined;
    if(k !== "players")
    {
        const gameKey = k as keyof Game;
        gamePropertyValue = game[gameKey];
    }

    if(!gamePropertyValue && k !== "players")
    {
        return false;
    }

    if(k === "players")
    {
        const min = Number(game.min_players);
        const max = Number(game.max_players);
        const filterValue = Number(value);

        if(isNaN(filterValue))
        {
            return false;
        }

        if(!isNaN(min))
        {
            if(!isNaN(max))
            {
                return filterValue >= min && filterValue <= max;
            }

            return filterValue >= min;
        }
        else if(!isNaN(max))
        {
            return filterValue <= max;
        }

        return true;
    }

    if(typeof value === "string" && (typeof gamePropertyValue === "string" || typeof gamePropertyValue === "number"))
    {

        if(k === "age")
        {
            const filterValue = Number(value);
            const gameValue = Number(gamePropertyValue);

            return !isNaN(filterValue) && !isNaN(gameValue)? gameValue <= filterValue : false;
        }

        return gamePropertyValue?.toString() === value;
    }

    if(k === "tags")
    {
        const gameTags = gamePropertyValue as string[];
        if(Array.isArray(value))
        {
            if(value.length > 0)
            {
                const filterTags = value as string[];
                return gameTags.some(t => filterTags.includes(t.trim()));
            }
            return true;
        }

        if(typeof value === "string")
        {
            return gameTags.some(t => t === value);
        }
    }

    return false;
};

const countFilters = (filters: Filters) => Object.keys(filters).reduce((count, k) => (filters[k as keyof Filters]?.length || 0) + count, 0);

const filterGames = (games: Game[], filters: Filters) => games.reduce((filteredGames: string[], game: Game) =>
{
    const {search, ...rest} = filters;
    let nameMatch = false
    if(search && search.length > 2)
    {
        nameMatch = game.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    else
    {
        nameMatch = true;
    }

    if(!nameMatch)
    {
        return filteredGames;
    }

    const filterKeys = Object.keys(rest);
    if(countFilters(filters) === 0)
    {
        return [
            ...filteredGames,
            game._id
        ];
    }
    let match = filterKeys.length === 0 || filterKeys.reduce<boolean>((result: boolean, filterKey: string, i: number) =>
    {
        const value = filters[filterKey as keyof Filters];

        if(!value || value.length === 0)
        {
            return result;
        }
        return result && applyFilter(game, filterKey as keyof Filters, value);

    }, true);

    if(!match)
    {
        return filteredGames;
    }

    return [
        ...filteredGames,
        game._id
    ];

}, []);


export const getSortTypeLabel = (sortParam: SortType) =>
{
    switch(sortParam)
    {
        case SortType.AZ: return "Alphabetical A-Z";
        case SortType.ZA: return "Alphabetical Z-A";
        case SortType.Newest: return "Newest To Oldest";
        case SortType.Longest: return "Longest";
        case SortType.Shortest: return "Shortest";
        case SortType.MaxPlayers: return "Max Players";
        case SortType.MinPlayers: return "Min Players";
        default: return "";
    }
};

export enum SortType
{
    AZ,
    ZA,
    Newest,
    Longest,
    Shortest,
    MaxPlayers,
    MinPlayers
}

const convertDurationToMinutes = (durationString?: string) =>
{
    if(!durationString)
    {
        return 0;
    }

    const values = durationString
        .replace("min", "")
        .replace("Min", "")
        .replace("MIN", "")
        .split("-");

    if(values.length === 0)
    {
        return 0;
    }

    let minutes = 0;

    const d1 = Number(values[0]);
    const d2 = values.length > 1? Number(values[1]) : 0;

    if(!isNaN(d1))
    {
        minutes = d1;
        if(!isNaN(d2))
        {
            minutes = Math.max(d1, d2);
        }
    }
    else if(!isNaN(d2))
    {
        return d2;
    }

    return isNaN(minutes)? 0 : minutes;
};


const convertPlayers = (players?: string) =>
{
    const p = Number(players?.trim());
    return isNaN(p)? 0 : p;
};

export const compareDateString = (a?: string, b?: string): number =>
{
    if(a && b)
    {
        return (new Date(b).getTime()) - (new Date(a).getTime());
    }
    else if (a)
    {
        return 1;
    }
    else if (b)
    {
        return -1;
    }
    return 0;
};


const sortGames = (games: Game[], sortParam: SortType) =>
{
    switch(sortParam)
    {
        case SortType.AZ:
            return games.sort((a, b) => a.name.localeCompare(b.name));
        case SortType.ZA:
            return games.sort((b, a) => a.name.localeCompare(b.name));
        case SortType.Longest:
            return games.sort((b, a) => convertDurationToMinutes(a.duration) - convertDurationToMinutes(b.duration));
        case SortType.Shortest:
            return games.sort((a, b) => convertDurationToMinutes(a.duration) - convertDurationToMinutes(b.duration));
        case SortType.MinPlayers:
            return games.sort((a, b) => convertPlayers(a.min_players) - convertPlayers(b.min_players));
        case SortType.MaxPlayers:
            return games.sort((b, a) => convertPlayers(a.max_players) - convertPlayers(b.max_players));
        case SortType.Newest:
            return games.sort((a, b) => a._created - b._created);
        default: 
            return games.sort((a, b) => a.name.localeCompare(b.name));
    }
};

export const filterAndSortGames = (allGames: Game[], filters: Filters, sortParam: SortType = SortType.AZ) =>
{
    const sorted = sortGames(allGames, sortParam);
    const filtered = filterGames(sorted, filters);

    return filtered;
};