import React, { useEffect, useState } from "react";
import { IPageProps } from "../App";
import api, { prefixImagePath } from "../services/BackendApi";
import { MenuResponse } from "../typings";
import "./MenuPage.css";

const MenuPage: React.FC<IPageProps> = ({onLoad}) => {

    const [menu, setMenu] = useState<MenuResponse>();

    useEffect(() =>
    {
        onLoad();
    }, [onLoad]);

    useEffect(() =>
    {
        if(!menu)
        {
            api.getMenu()
                .then(response => setMenu(response));
        }
    }, [menu]);

    if(!menu)
    {
        return null;
    }

    return (

        <div className="content menu-page">

            <div className="menu-images">
                {menu.images.map(image => {
                    return (
                        <img className="menu-image" src={prefixImagePath(image.path)} />
                    );
                }
                )}
            </div>
            <div className="button-wrapper">
                {menu.file_pdf? <a target="_blank" rel="noreferrer" className="banner-button" href={prefixImagePath(menu.file_pdf)} download>Download menu</a> : null}
            </div>
        </div>
    );
};

export default MenuPage;